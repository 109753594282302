import React from 'react'
import Layout from '../layout/layout'
import twitterIcon from '../images/twitter-square-brands.svg'
import linkedinIcon from '../images/linkedin-brands.svg'
import sideImage from '../images/img-registration.svg'

import SEO from "../layout/seo"
import './styles/registration-complete.scss';

const registrationComplete = () => {
    
    return (

        
        <Layout ><div className="container registration-complete" style={{
            padding: `5rem 0`
        }}>
            <SEO title="Registration Complete" />
            <h1>Registration Complete!</h1>
            <h2 className="turquoise">See you on June 14, 2022</h2>

            <div>
                <p>In the meantime, check out the brand new <a href="https://ignite.apache.org/" target="_blank" className="external turquoise">Apache Ignite website</a>.</p>
                <p>While visiting the site you can learn about key Ignite features, download the latest software release and join the community email forum.</p>
                <p>We will send Ignite summit updates about the speakers and sessions as we get closer to the event.</p>
            </div>

            </div></Layout>
        
    )
}

export default registrationComplete